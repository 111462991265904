* {
    margin: 0;
    padding: 0;
}
.cusmodal {
    width: 100%;
    height: 80%;
    top: 100px;
    display: flex;
    justify-content: center;
    position: relative;
}

.cusmodal .popup {
    background: white;
    height: max-content;
    width: fit-content;
    border-radius: 1rem;
    top: -500rem;
    left: 50%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1.3rem;
    z-index: 999999;
    box-shadow: 2px 3px 10px rgba(178, 181, 184, 0.904);
    transition: 0.5s ease-in-out;
}

.cusmodal .popup .close {
    position: relative;
    display: flex;
    align-self: flex-end;
    cursor: pointer;
}

.cusmodal .popup .input {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 15px;
    padding: .8rem 1rem;
}

.cusmodal .popup button {
    width: 100%;
    background: #0E56C2;
    color: white;
    border: none;
    padding: .8rem 0rem;
    border-radius: 15px;
    cursor: pointer;
}

.cusmodal .popup input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
}
.cusmodal .popup select {
    min-width: 180px;
    margin-right: 4%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    padding: .5rem;
}

.modal-sm
{
    inset: auto !important;
    box-shadow: 2px 3px 10px rgba(178, 181, 184, 0.904);
    width: fit-content;
    display: contents;
}
.ReactModal__Overlay {
    background-color: rgb(91 89 89 / 75%) !important;
    z-index: 9999999;
}

.cusmodal .popup .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 15px;
    margin:0;
    padding: .5rem 1rem;
}