* {
    margin: 0;
    padding: 0;
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    background: #fff;
    z-index: 9999;
    position: fixed;
    box-shadow: 7px 2px 10px 1px #ebebeb;
}


.header .headerAccountSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
}

.headerAccountSec .search {
    display: flex;
    border: 1px solid black;
    padding: 1rem 1rem;
    background: #FAFAFA;
    border-radius: 15px;
    width: 400px;
    margin-left: 2rem;
}

.search button {
    border: none;
    background: none;
    gap: .5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-weight: 700;
    font-size: 15px;
}

.search input {
    background: none;
    outline: none;
    border: none;
    width: 100%;
    height: fit-content;
    margin-left: 1rem;
    font-size: 18px;
}

.headerAccountSec .NA {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.NA .notification, .NA .logout {
    padding: 1rem 2rem;
    border-right: 1px solid black;
}

.NA .account {
    display: flex;
    padding: 0rem 1rem;
}

.account .AD {
    margin-left: .8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AD p {
    font-size: 18px;
    color: #40558A;
    font-weight: 600;
}

.AD small {
    font-size: 11px;
    color: var(--black-medium-emphasis, rgba(0, 0, 0, 0.60));
}

.header .closeNavbar {
    display: none;
}

.header .toddleNavbar {
    display: none;
}

@media screen and (max-width:890px) {
    .headerAccountSec .search {
        width: 280px;
    }
}

@media screen and (max-width:828px) {
    .headerAccountSec .search {
        margin-left: unset;
    }

}




@media screen and (max-width:768px) {
    .header .headerAccountSec {
        position: absolute;
        background: white;
        height: max-content;
        width: 80%;
        border-radius: 1rem;
        top: -500rem;
        left: 50%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        transform: translate(-50%);
        z-index: 22222;
        box-shadow: 2px 3px 10px rgba(178, 181, 184, 0.904);
        transition: 0.5s ease-in-out;



        .closeNavbar {
            position: absolute;
            top: 1rem;
            right: 1rem;
            color: var(--PrimaryColor);
            display: flex;

            &:hover {
                color: var(--SecondaryColor);
            }

        }
    }

    .headerAccountSec .search {
        margin-top: 2rem;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .header .toddleNavbar {
        margin-right: 2rem;
    }

    .header .activeNavbar {
        top: 7rem;
    }

    .headerAccountSec .NA {
        margin-top: 1rem;
    }

    .header .closeNavbar {
        display: flex;
    }

    .header .toddleNavbar {
        display: flex;
    }

    .headerAccountSec .search {
        display: flex;
        border: 1px solid black;
        padding: .5rem .8rem;
        background: #FAFAFA;
        border-radius: 15px;
        width: 400px;
    }

    .search button {
        border: none;
        background: none;
        gap: .5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        outline: none;
        font-weight: 700;
        font-size: 12px;
    }

    .search input {
        background: none;
        outline: none;
        border: none;
        width: 100%;
        height: fit-content;
        margin-left: 1rem;
        font-size: 13px;
    }


    .AD p {
        font-size: 15px;
    }

    .AD small {
        font-size: 10px;
    }


}

@media screen and (max-width:750px) {
    .headerAccountSec .search {
        width: 80%;
    }
}


@media screen and (max-width:320px) {

    .headerAccountSec .NA {
        flex-direction: column;
    }

    .NA .notification {
        border-right: none;
    }

}
.menu ul li {
    display: inline-block;
    padding: 10px 20px;
}
.menu ul li a {
    color: #40558A;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
}
