* {
    padding: 0;
    margin: 0;
}

.advertisement {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
}

.advertisement .in {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.in .title {
    display: flex;
}

.title p {
    font-size: 22px;
    color: #1F384C;
    font-weight: 400;
    margin-bottom: 1rem;
}
.in15{
display: flex;
width: 100%;
}
.in15 .lft {
    width: 20%;
    display: flex;
    flex-direction: column;
}

.lft button {
    padding: .5rem 1rem;
    border: none;
    outline: none;
    background: none;
}
.lft button:hover {
    cursor:pointer
}
.lft .active {
    background: #2057a9;
    color: #ffffff;
    border-top-right-radius: 30px;
    border-bottom-right-radius:30px
    
}

/* .lft button:focus {
    background: #2057a9;
    color: #ffffff;
} */

.in15 .rht {
    width: 80%;
}

.in .box12 {
    width: 100%;
    display: flex;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(207, 236, 248, 0.50);
    gap: 1rem;
}

.box12 .picUpload {
    width: fit-content;
    margin: 1rem;
}

.picUpload .outline {
    border-radius: 10px;
    border: 1px dashed var(--primary-color, #0E56C2);
    background: #FFF;
    padding: 1.3rem 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.outline svg {
    background: #DEE8FF;
    padding: 1rem;
    border-radius: 42%;
    cursor: pointer;
}

.outline p {
    font-size: 13px;
    margin-top: 1rem;
    font-weight: 500;
}

.box12 .inputSec {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    justify-content: center;
}

.inputSec .input {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.box12 .inputSec .input input {
    border-radius: 15px;
    border: 1px solid #C5C2C2;
    background: #FFF;
    height: 20px;
    padding: 0.6rem 1rem;
}

.inputSec p {
    padding: 1rem 1rem;
    font-weight: 500;
    border-radius: 5px;
    background: #D3F8EB;
}

.in .addCard {
    margin-top: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px rgba(207, 236, 248, 0.50);
    justify-content: space-between;
    margin-bottom: 2rem;
    height: 68vh;
    display: flex;
    flex-direction: column;
}

.addCard .leftSec {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 300px;
}

.in .title2 {
    font-size: 22px;
    color: #1F384C;
    font-weight: 400;
    margin-top: 3rem;
}

.addCard .leftSec img {
    height: 90%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
}

.leftSec p {
    display: flex;
    justify-content: center;
    padding: 1rem 0.5rem;
    border-radius: 5px;
    font-weight: 500;
    background: #D3F8EB;
    margin-top: 1rem;
}

.addCard .logo {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addCard .logo img {
    height: 110px;
    width: 110px;
}

.details {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 1rem;

}

.details p {
    font-family: Arial;
    font-size: 12px;
    font-weight: 500;
}

.details p small {
    font-size: 15px;
    color: #0E56C2;
}

.in .button51 {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
}

.card {
    /* border-radius: 8px; */
    padding: 15px;
    width: 95%;
    height: 170px;
    box-sizing: border-box;
    margin: 0 10px 10px 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    border-top: 1px solid #b4b0b0;
}

.corner-tag {
    position: absolute;
    top: -2px;
    left: -20px;
    background-color: #ff5555;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.76);
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 10px;
    font-family: Arial;
    transform: rotate(-45deg);
}
.boxad {
    /* border: 1px solid #0E56C2; */
    margin: 15px 0;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.adsInfo{
    background-color: #f3efef;
    padding: 10px 0;
    border-bottom: 1px solid #b4b0b0;
    margin-bottom: 10px;

}
.flexBtns{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding:0 8px 0 8px;
}
.adsInfo ul li {
    display: inline-block;
    padding: 5px 10px;
}
.card-image {
    width: 30%;
    height: auto;
    display: block;
    border-radius: 5px;
    cursor: pointer;
}

.card-details {
    margin-top: 10px;
}

.card-title {
    margin: 0;
}

.card-description {
    margin: 0;
    color: #555;
}

.adcode {
    background: #D3F8EB;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

.container .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  }
  
  .dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .dropdown li {
    padding: 8px 12px;
  }
  
  .dropdown ul li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.image-modal {
    position: relative;
    display: inline-block;
}

.zoomable-image {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.zoomable-image:hover {
    transform: scale(1.1);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}

.close {
    color: #fff;
    background-color: #000000;
    position: relative;
    top: -120px;
    right: -150px;
    font-size: 15px;
    border-radius: 50%;
    border: 2px solid #333;
}

.zoomed-image {
    max-width: 100%;
    max-height: 70vh;
}

@media screen and (max-width:1111px) {
    .details p {
        font-size: 16px;
    }


    .details p small {
        font-size: 16px;
    }
}

@media screen and (max-width:760px) {
    .addCard .leftSec {
        width: 100%;
    }

    .in .title p {
        font-size: 18px;
    }

    .addCard .logo img {
        height: 100px;
        width: 100px;
    }

    .in .title2 {
        font-size: 18px;
        color: #1F384C;
        font-weight: 400;
        margin-top: 3rem;
    }

    .inputSec p {
        font-size: 12px;
    }

    .leftSec p {
        font-size: 12px;
    }

    .in .addCard {
        flex-wrap: wrap;
    }

    .addCard .logo {
        width: fit-content;
    }

    .in .box12 {
        flex-wrap: wrap;
        justify-content: center;
    }

    .box12 .picUpload {
        width: 90%;
        margin: 1rem;
    }

    .inputSec .input {
        justify-content: center;
    }

    .input input {
        width: 100%;
    }

    .details p {
        font-size: 13px;
        font-weight: 500;
    }

    .details p small {
        font-size: 13px;
    }

    .column {
        flex: 1;
        border: 1px solid #ccc;
        padding: 10px;
    }

    .panel-header {
        padding: 0.75rem 1.25rem;
        background: #fff;
        border-bottom: 1px solid #dde3e8;
    }

    h6,
    .h6 {
        font-size: 1rem;
        line-height: 1.2;
    }
}

@media screen and (max-width:451px) {
    .in .addCard {
        justify-content: center;
    }

    .addCard .logo img {
        height: 80px;
        width: 80px;
    }

    .addCard .logo {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }
}