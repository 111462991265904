* {
    margin: 0;
    padding: 0;
}

.addsDetails {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.addsDetails .in {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.addsDetails .upr {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 3rem;
}

.addsDetails .upr .box1 {
    display: flex;
    align-items: center;
    gap: .8rem;
}

.box1 .docIcn {
    background: rgba(36, 58, 255, 0.19);
    padding: .8rem;
    border-radius: 5px;
}

.box1 .moneyIcn {
    background: rgba(64, 228, 169, 0.23);
    padding: .8rem 1rem;
    border-radius: 5px;
}


.box1 .inBox1 {
    display: flex;
    flex-direction: column;
}

.inBox1 small {
    font-size: 14px;
    color: #9F9999;
    font-weight: 400;
}

.inBox1 p {
    font-size: 20px;
    color: black;
    font-weight: 600;
}

.in .Low {
    display: flex;
    gap: 5rem;
    margin-top: 2rem;
}



.addsDetails .Low .box1 {
    display: flex;
    align-items: center;
    gap: .8rem;
}

.box1 .docIcn {
    background: rgba(36, 58, 255, 0.19);
    padding: .8rem;
    border-radius: 5px;
}

.box1 .moneyIcn {
    background: rgba(64, 228, 169, 0.23);
    padding: .8rem 1rem;
    border-radius: 5px;
}


.box1 .inBox1 {
    display: flex;
    flex-direction: column;
}

.inBox1 small {
    font-size: 14px;
    color: #9F9999;
    font-weight: 400;
}

.inBox1 p {
    font-size: 20px;
    color: black;
    font-weight: 600;
}

.Low .approved {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.approved p {
    color: #2057A9;
    font-size: 25px;
    font-weight: 700;
}

.in .code {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 5rem;
    width: fit-content;
    border-radius: 3px;
    background: #D3F8EB;
    margin-bottom: 3rem;
}

.code p{
    font-weight: 600;
    color: black;
}




@media screen and (max-width:960px) {
   
    .addsDetails .in .upr .box1 {
        display: flex;
        align-items: center;
        gap: .8rem;
    }

    .box1 .docIcn {
        background: rgba(36, 58, 255, 0.19);
        padding: .6rem;
        border-radius: 5px;
    }

    .box1 .moneyIcn {
        background: rgba(64, 228, 169, 0.23);
        padding: .6rem .8rem;
        border-radius: 5px;
    }


    .box1 .inBox {
        display: flex;
        flex-direction: column;
    }

    .inBox1 small {
        font-size: 12px;
        color: #9F9999;
        font-weight: 400;
    }

    .inBox1 p {
        font-size: 18px;
        color: black;
        font-weight: 600;
    }

}



@media screen and (max-width:830px) {
   

    .addsDetails .in .upr .box1 {
        display: flex;
        align-items: center;
        gap: .8rem;
    }

    .box1 .docIcn {
        background: rgba(36, 58, 255, 0.19);
        padding: .4rem;
        border-radius: 5px;
    }

    .box1 .moneyIcn {
        background: rgba(64, 228, 169, 0.23);
        padding: .4rem .6rem;
        border-radius: 5px;
    }

    .box1 .inBox1 {
        display: flex;
        flex-direction: column;
    }

    .inBox1 small {
        font-size: 10px;
        color: #9F9999;
        font-weight: 400;
    }

    .inBox1 p {
        font-size: 16px;
        color: black;
        font-weight: 600;
    }

}


@media screen and (max-width:735px) {
    .box1 .docIcn {
        background: rgba(36, 58, 255, 0.19);
        padding: .3rem;
        border-radius: 5px;
    }

    .box1 .moneyIcn {
        background: rgba(64, 228, 169, 0.23);
        padding: .3rem .5rem;
        border-radius: 5px;
    }

    .checked img {
        margin-right: 8px;
        height: 31px;
        width: 31px;
    }


    .in .Low {
        display: flex;
        gap: 3rem;
        margin-top: 2rem;
    }
    
    
    
    .addsDetails .Low .box1 {
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    
    .box1 .docIcn {
        background: rgba(36, 58, 255, 0.19);
        padding: .5rem;
        border-radius: 5px;
    }
    
    .box1 .moneyIcn {
        background: rgba(64, 228, 169, 0.23);
        padding: .5rem .7rem;
        border-radius: 5px;
    }
    
    
    .box1 .inBox1 {
        display: flex;
        flex-direction: column;
    }
    
    .inBox1 small {
        font-size: 12px;
        color: #9F9999;
        font-weight: 400;
    }
    
    .inBox1 p {
        font-size: 18px;
        color: black;
        font-weight: 600;
    }
    
    .Low .approved {
        display: flex;
        align-items: center;
        gap: 1.8rem;
    }
    
    .approved p {
        color: #2057A9;
        font-size: 23px;
        font-weight: 700;
    }
    
    .in .code {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 5rem;
        width: fit-content;
        background: #D3F8EB;
    }
    
    .code p{
        font-weight: 600;
        color: black;
    }
    
    
}


@media screen and (max-width:660px) {
    .addsDetails .in .upr .box1 {
        display: flex;
        align-items: center;
        gap: .3rem;
    }


    .in .Low {
        display: flex;
        gap: 2rem;
        margin-top: 2rem;
    }
    
    
    
    .addsDetails .in .Low .box1 {
        display: flex;
        align-items: center;
        gap: .3rem;
    }
    
    .box1 .docIcn {
        background: rgba(36, 58, 255, 0.19);
        padding: .3rem;
        border-radius: 5px;
    }
    
    .box1 .moneyIcn {
        background: rgba(64, 228, 169, 0.23);
        padding: .3rem .5rem;
        border-radius: 5px;
    }
    
    
    .box1 .inBox1 {
        display: flex;
        flex-direction: column;
    }
    
    .inBox1 small {
        font-size: 12px;
        color: #9F9999;
        font-weight: 400;
    }
    
    .inBox1 p {
        font-size: 18px;
        color: black;
        font-weight: 600;
    }
    
    .Low .approved {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }
    
    .approved p {
        color: #2057A9;
        font-size: 21px;
        font-weight: 700;
    }
    
    .in .code {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .8rem 5rem;
        width: fit-content;
        background: #D3F8EB;
    }
    
    .code p{
        font-weight: 600;
        color: black;
    }
    
    
}

@media screen and (max-width:600px) {
    .addsDetails .in .Low {
      flex-wrap: wrap;
    }
}


@media screen and (max-width:468px) {
    .addsDetails .in .code {
      padding: .5rem 3rem;
    }
    .code p{
        font-size: 15px;
    }
}

@media screen and (max-width:395px) {
    .addsDetails .in .code {
      padding: .5rem 2rem;
    }
    .code p{
        font-size: 14px;
    }
}

@media screen and (max-width:354px) {
    .addsDetails .in .code {
      padding: .3rem 1rem;
    }
    .code p{
        font-size: 13px;
    }
}

@media screen and (max-width:330px) {
    .addsDetails .in .code {
      padding: .3rem .5rem;
      justify-content: center;
    }
    .code p{
        font-size: 12px;
    }
}