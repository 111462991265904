.butt {
    padding: 0.8rem 2rem;
        gap: 1rem;
        display: flex;
        align-items: center;
        border-radius: 15px;
        border: none;
        background: #0E56C2;
        color: white;
        cursor: pointer;
        text-decoration: none;
}
.cusmodal .popup .close {
    top: 0;
    right: 0;
    background: white;
    border: 0;
}