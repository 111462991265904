* {
    margin: 0;
    padding: 0;
}

.dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    top: 90px;
}

.dashboard .in {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.in .wardHead {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    align-items: center;
}

.wardHead button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0E56C2;
    color: white;
    padding: .8rem 2rem;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    gap: .5rem;
}

.wardHead p {
    font-size: 22px;
    color: #1F384C;
}

.in .wardList {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 1rem 0;
 
    gap: 1.8rem;
}

.wardList .box {
    padding: 1.3rem 1.5rem;
    box-shadow: 0px 1px 15px rgb(227, 230, 248);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 18%;
}

.box .line1 {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.line1 small {
    font-size: 20px;
    color: black;
    font-weight: bold;
}

.line1 p {
    font-size: 35px;
    color: black;
    font-weight: bold;
}

.box .line2 {
    display: flex;
    margin-top: .6rem;
    align-items: center;
    gap: .5rem;
}

.line2 p {
    font-size: 14px;
}

.in .assign {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
    align-items: center;
}

.assign .btns {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.btns button {
    padding: .8rem 2rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: none;
    background: #0E56C2;
    color: white;
    cursor: pointer;
}

.assign p {
    font-size: 22px;
    color: #1F384C;
}

.dashboard .in .wardAssign {
    display: flex;
    flex-wrap: wrap;
 
    margin-bottom: 3rem;
    margin-top: 1rem;
    overflow: hidden
}

.wardAssign .admins {
    display: flex;
    padding: .5rem;
    gap: .5rem;
    margin-top: 1.5rem;
    width: 23%;
}

.admins .name {
    display: flex;
    flex-direction: column;
}

.name p {
    font-size: 15px;
    font-weight: 700;
    color: black;
}

.name small {
    color: #9F9999;
    font-size: 11px;
}


.dashboard .popup1 {
    position: absolute;
    background: white;
    height: max-content;
    width: fit-content;
    border-radius: 1rem;
    top: -500rem;
    left: 50%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1.3rem;
    transform: translate(-50%);
    z-index: 999999;
    box-shadow: 2px 3px 10px rgba(178, 181, 184, 0.904);
    transition: 0.5s ease-in-out;
}

.popup1 .close {
    position: relative;
    display: flex;
    align-self: flex-end;
    cursor: pointer;
}

.popup1 .input {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 15px;
    padding: .8rem 1rem;
}

.popup1 button {
    width: 100%;
    background: #0E56C2;
    color: white;
    border: none;
    padding: .8rem 0rem;
    border-radius: 15px;
    cursor: pointer;
}

.popup1 input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
}

.dashboard .active1 {
    top: 8%;
    padding: 2rem;
}

.dashboard .popup2 {
    position: absolute;
    background: white;
    height: max-content;
    width: fit-content;
    border-radius: 1rem;
    top: -500rem;
    gap: 1.3rem;
    left: 50%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 2rem;
    transform: translate(-50%);
    z-index: 999999;
    box-shadow: 2px 3px 10px rgba(178, 181, 184, 0.904);
    transition: 0.5s ease-in-out;
}

.popup2 .close {
    position: relative;
    display: flex;
    align-self: flex-end;
    cursor: pointer;
}

.dashboard .active2 {
    top: 7rem;
}

.popup2 .input {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 15px;
    padding: .4rem 0rem;
}

.popup2 button {
    width: 100%;
    background: #0E56C2;
    color: white;
    border: none;
    padding: .8rem 0rem;
    border-radius: 15px;
    cursor: pointer;
}

.popup2 .addAdmin {
    width: fit-content;
    background: #0E56C2;
    color: white;
    border: none;
    padding: .8rem 1.3rem;
    border-radius: 15px;
    cursor: pointer;
}

.popup2 input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    padding: .5rem;
}
.popup2 select {
    min-width: 180px;
    margin-right: 4%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    padding: .5rem;
}

.dashboard .popup3 {
    position: absolute;
    background: white;
    height: max-content;
    width: fit-content;
    border-radius: 1rem;
    top: -500rem;
    left: 50%;
    gap: 1.3rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    transform: translate(-50%);
    z-index: 999999;
    box-shadow: 2px 3px 10px rgba(178, 181, 184, 0.904);
    transition: 0.5s ease-in-out;
}

.popup3 .close {
    position: relative;
    display: flex;
    align-self: flex-end;
    cursor: pointer;
}

.dashboard .active3 {
    top: 7rem;
}

.popup3 .input {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 15px;
    padding: .8rem 1rem;
}

.popup3 button {
    width: 100%;
    background: #0E56C2;
    color: white;
    border: none;
    padding: .8rem 0rem;
    border-radius: 15px;
    cursor: pointer;
}

.popup3 input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
}

.download {
    padding: .8rem 2rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: none;
    background: #0E56C2;
    color: white;
    cursor: pointer;
}


@media screen and (max-width:834px) {
    .assign .btns {
        display: flex;
        flex-direction: column;
    }

    .btns button {
        padding: .5rem 1.2rem;
    }

    .wardHead button {
        padding: .5rem 1.2rem;
    }

}

@media screen and (max-width:750px) {


    .wardHead button {
        padding: .5rem 1.8rem;
        font-size: 13px;
    }

    .wardHead p {
        font-size: 20px;
        color: #1F384C;
    }

    .in .wardList {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 1rem;
        justify-content: space-around;
        gap: 1rem;
    }

    .wardList .box {
        padding: 1rem 1.2rem;
        box-shadow: 0px 1px 15px rgb(227, 230, 248);
        display: flex;
        flex-direction: column;
        border-radius: 10px;
    }

    .box .line1 {
        display: flex;
        align-items: center;
        gap: .3rem;
    }

    .line1 small {
        font-size: 18px;
        color: black;
        font-weight: bold;
    }

    .line1 p {
        font-size: 32px;
        color: black;
        font-weight: bold;
    }

    .box .line2 {
        display: flex;
        margin-top: .4rem;
        align-items: center;
        gap: .3rem;
    }

    .line2 p {
        font-size: 13px;
    }

    .in .assign {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 2rem;
        align-items: center;
    }

    .assign .btns {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .btns button {
        padding: .5rem 1.2rem;
        gap: 1rem;
        display: flex;
        align-items: center;
        border-radius: 15px;
        border: none;
        background: #0E56C2;
        color: white;
        cursor: pointer;
    }

    .assign p {
        font-size: 20px;
        color: #1F384C;
    }

    .dashboard .in .wardAssign {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 3rem;
        margin-top: 1rem;
    }

    .wardAssign .admins {
        display: flex;
        align-items: center;
        padding: .3rem;
        gap: .4rem;
        border-right: 1px solid #9F9999;
        margin-top: 1.5rem;
    }

    .admins .name {
        display: flex;
        flex-direction: column;
    }

    .name p {
        font-size: 13px;
        font-weight: 700;
        color: black;
    }

    .name small {
        color: #9F9999;
        font-size: 10px;
    }
}


@media screen and (max-width:615px) {
    .dashboard .popup1 {
        position: fixed;
        width: 80%;
    }

    .dashboard .popup2 {
        position: fixed;
        width: 80%;
    }

    .dashboard .popup3 {
        position: fixed;
        width: 80%;
    }
}

@media screen and (max-width:500px) {

    .wardHead button {
        padding: .5rem 1rem;
        font-size: 12px;
    }

    .wardHead p {
        font-size: 18px;
        color: #1F384C;
    }

    .in .wardList {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 1rem;
        justify-content: space-around;
        gap: .7rem;
    }

    .wardList .box {
        padding: .8rem 1rem;
        box-shadow: 0px 1px 15px rgb(227, 230, 248);
        display: flex;
        flex-direction: column;
        border-radius: 10px;
    }

    .box .line1 {
        display: flex;
        align-items: center;
        gap: .3rem;
    }

    .line1 small {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }

    .line1 p {
        font-size: 28px;
        color: black;
        font-weight: bold;
    }

    .box .line2 {
        display: flex;
        margin-top: .3rem;
        align-items: center;
        gap: .3rem;
    }

    .line2 p {
        font-size: 11px;
    }

    .in .assign {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 2rem;
        align-items: center;
    }

    .assign .btns {
        display: flex;
        align-items: center;
        gap: .8rem;
    }

    .btns button {
        padding: .3rem 1rem;
        gap: 1rem;
        display: flex;
        align-items: center;
        border-radius: 15px;
        border: none;
        background: #0E56C2;
        color: white;
        cursor: pointer;
    }

    .assign p {
        font-size: 18px;
        color: #1F384C;
    }

    .dashboard .in .wardAssign {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 3rem;
        margin-top: 1rem;
    }

    .wardAssign .admins {
        display: flex;
        align-items: center;
        padding: .3rem;
        gap: .4rem;
        border-right: 1px solid #9F9999;
        margin-top: 1.5rem;
    }

    .admins .name {
        display: flex;
        flex-direction: column;
    }

    .name p {
        font-size: 11px;
        font-weight: 700;
        color: black;
    }

    .name small {
        color: #9F9999;
        font-size: 8px;
    }
}

button:disabled,
button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}