.highlightText {
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
}

.editButton {
    background: #0E56C2;
    color: #ffffff;
    border: none;
    padding: 8px 5px 8px 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}