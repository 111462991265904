* {
    margin: 0;
    padding: 0;
}

.li button:active {
    background:  #FAFAFA;
}

.li .active {
    background: #2057a9;
    color: #ffffff;
}

.main .li,
li {
    list-style-type: none;
}
.main .li button{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.main .li button div {
    display: flex;
}
.main .li button div div {
    align-self: center;
    padding: 0px 4px;
} 
.main .li button div svg {
    color: #2056a6;
}
.main .li .active div svg {
    color: #fbfdff;
}


.main {
    height: 100vh;
    width: 240px;
    margin: 0;
    background:  #FAFAFA;
    left: 0;
    padding: 0;
    position: fixed;
    z-index: 9999998;
    overflow-y: scroll;
}

.main .logo {
    height: 80px;
    width: 80px;
    margin: 1rem 2rem;
    cursor: pointer;
}
@media screen and (max-width:750px) {
    .main .logo {
        height: 60px;
        width: 60px;
        margin: 1rem 2rem;
        cursor: pointer;
    }


}
.logoli {
    border-bottom:1px solid #cfcaca ;
    text-align: center;
    margin: 0px 10px;
}
.li {
    display: flex;
    flex-direction: column;
}

.li button {
    width: 100%;
    height: 100%;
    padding: 1.3rem 1.3rem 1.3rem .8rem;
    border: none;
    color: #0E56C2;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.1px;
    background:  #FAFAFA;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
}



@media screen and (max-width:900px) {
    .main {
        display: none;
    }
}